<template>
  <div>
    <div class="container">
      <div class="links sctp-mar-t10">
        <div class="link-title">友情链接</div>
        <div class="link-content">
          <div class="link" v-for="item in linkList">
            <a :href="item.weblink" target="_blank">
              <div>{{ item.webname }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {getlinkList} from '@/apis/index'
export default {
  name: "FooterLinks",
  data() {
    return {
      linkList: [],
    }
  },
  created() {
    this.getlinkList();
  },
  methods: {
    getlinkList() {
      getlinkList().then(res => {
        if (res.retdata) {
          this.linkList = res.retdata;
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">

.links .link-title {
  font-size: 14px;
}

.links .link-content {
  margin-bottom: 10px;
  font-size: 12px;
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
}

.link-content .link {
  margin-left: 15px;
  margin-top: 10px;
}

.links {
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
}
</style>
